import { HAS_WINDOW } from '@eventbrite/feature-detection';
import { ThemeTokens, THEME_TYPES, TokenOptions, VARIANTS } from '../types';
import { styles } from './styles';
import {
    getEdgyThemeTokens,
    getMellowThemeTokens,
    getSmartThemeTokens,
    getVibrantThemeTokens,
} from './themes';

export interface CachedStyle {
    className: string;
    styles: string;
}

export const randomCharGenerator = () => {
    const upper = !!Math.round(Math.random());
    const charIdx = Math.ceil(Math.random() * 26);
    const char = String.fromCharCode(charIdx + 64);
    return upper ? char.toUpperCase() : char.toLowerCase();
};

export const generateRandomClassPrefix = () =>
    'xxxxxx'.split('').map(randomCharGenerator).join('');

export const generateThemeStyles = (
    theme: THEME_TYPES | 'Core',
    token: ThemeTokens,
    options?: TokenOptions,
) => {
    // Create a way to access the query params easily
    const params = HAS_WINDOW
        ? new Proxy<any>(new URLSearchParams(window.location.search), {
              get: (searchParams, prop: string) => searchParams.get(prop),
          })
        : {};

    // Logic to support backwards compatibility. If the theme is Core, a legacy theme,
    // then set the variant to core-dark, otherwise set it to mood_01. This is to support
    // the old MLPs that did have variant options on the modules.
    let variant =
        theme === 'Core'
            ? VARIANTS.CORE_DARK
            : options?.variant || VARIANTS.MOOD_01;

    // Support overriding the variant by query param
    variant = params.variant || variant;

    variant = Object.values(VARIANTS).includes(variant)
        ? variant
        : VARIANTS.CORE_DARK; // Set to default variant in case an invalid one was given.

    // Logic to support backwards compatibility. Legacy MLPs have 5 themes instead of 4,
    // This allows for overriding the passed in theme in case it calls for Core, which has
    // been moved to a set of shared variants. It falls back to Edgy as a default. Logic
    // above ensures that the variant is set for Core so the styles work regardless of the theme
    const themeOverride =
        params.theme || (theme === 'Core' ? THEME_TYPES.EDGY : theme);

    let tokens = getEdgyThemeTokens(token, variant);
    switch (themeOverride) {
        case THEME_TYPES.EDGY:
            tokens = getEdgyThemeTokens(token, variant);
            break;
        case THEME_TYPES.MELLOW:
            tokens = getMellowThemeTokens(token, variant);
            break;
        case THEME_TYPES.SMART:
            tokens = getSmartThemeTokens(token, variant);
            break;
        case THEME_TYPES.VIBRANT:
            tokens = getVibrantThemeTokens(token, variant);
            break;
        default:
            tokens = getEdgyThemeTokens(token, variant);
    }
    const className = `${generateRandomClassPrefix()}__${token}-${variant}`;

    return [className, styles[token](className, tokens, options?.config)];
};

const isObject = (test: any) =>
    Object.prototype.toString.call(test) === '[object Object]';

export const generateCacheKey = (
    token: ThemeTokens,
    options?: TokenOptions,
) => {
    const optionsKeys = options
        ? Object.values(options)
              .filter((v) => !!v && v !== 'default')
              .map((v) => {
                  if (isObject(v)) {
                      return Object.values(v).join('.');
                  }
                  return v;
              })
              .join('.')
        : '';
    return optionsKeys ? `${token}.${optionsKeys}` : token;
};

export const createThemeStylesFromCache = (
    cachedStyles: Record<string, CachedStyle>,
) => {
    return Object.values(cachedStyles)
        .map((cache) => cache.styles)
        .join('\n');
};

export const isExternalUrl = (url: string): boolean => {
    const eventbriteDomainRegex = new RegExp(
        `^https:\/\/www\.eventbrite\.([a-z]{2,}|[a-z]{2,}\.[a-z]{2,})($|\/.*$)`,
    );
    const absoluteUrlRegex = new RegExp(`^(https?):\/\/[^ "]+$`);
    // Check if the URL is relative
    const isRelative = !absoluteUrlRegex.test(url);
    // If the URL is relative
    if (isRelative) {
        return false;
    }
    // If the URL is from Eventbrite
    if (eventbriteDomainRegex.test(url)) {
        return false;
    }
    // If the URL is external returns true
    return true;
};

export const updateExternalLinks = (rawHtmlBlock: string): string => {
    if (rawHtmlBlock) {
        const htmlContent = rawHtmlBlock;
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');

        // Skip modification if iframe is present
        if (doc.querySelector('iframe')) {
            return htmlContent;
        }
        const anchors = doc.querySelectorAll('a');

        anchors.forEach((anchor) => {
            const href = anchor.getAttribute('href');
            const isURLExternal = href ? isExternalUrl(href) : false;
            if (isURLExternal) {
                anchor.setAttribute('target', '_blank');
                anchor.setAttribute('rel', 'nofollow noopener noreferrer');
            }
        });

        const headContent = doc.head ? doc.head.innerHTML : '';
        const bodyContent = doc.body ? doc.body.innerHTML : htmlContent;

        const finalUpdatedHtmlContent = `
            <html>
                <head>${headContent}</head>
                <body>${bodyContent}</body>
            </html>
        `.trim();

        return finalUpdatedHtmlContent;
    } else {
        return '';
    }
};
