import * as React from 'react';
import { EBreakpointValues, ESizes } from '../types';
import { Block } from './Block';
import { OrderedSizes } from './useMediaQuery';

const PixelRem = 1 / 16;

interface ITextOptions {
    fontSize: number;
    lineHeight: number;
}

type IBreakPoints = Partial<Record<ESizes, ITextOptions>>;

interface IResponsiveTextBaseProps {
    component?: string;
    base: ITextOptions;
    [key: string | number]: any;
    applyCSSToChild?: boolean;
}

export type IResponsiveTextProps = IResponsiveTextBaseProps & IBreakPoints;

export const getStyles = (
    base: ITextOptions,
    sizes: IBreakPoints,
    className: string,
) => {
    const styles = OrderedSizes.map((size) => {
        const { fontSize, lineHeight } = sizes[size] || base;
        return createMediaString(
            className,
            EBreakpointValues[size],
            fontSize,
            lineHeight,
        );
    })
        .reverse()
        .join('\n');

    return styles;
};

const createMediaString = (
    className: string,
    mediaSize: EBreakpointValues,
    fontSize: number,
    lineHeight: number,
    applyCSSToChild?: boolean,
) => {
    return `
        @media (min-width: ${mediaSize}px) {
            .${className.split(' ').join('.')} ${applyCSSToChild ? '> *' : ''}{
                font-size: ${fontSize * PixelRem}rem;
                line-height: ${lineHeight * PixelRem}rem;
            }
        }
    `;
};

export const ResponsiveText: React.FC<IResponsiveTextProps> = ({
    base,
    component = 'div',
    applyCSSToChild,
    ...props
}) => {
    let responsiveClassName = `base${base.fontSize}${base.lineHeight}`;
    OrderedSizes.forEach((size) => {
        const settings = props[size];
        if (settings) {
            responsiveClassName =
                responsiveClassName +
                `-${size}${settings.fontSize}${settings.lineHeight}`;
        }
    });
    const className = `${props.className} ${responsiveClassName.replaceAll(
        '.',
        '',
    )}`;

    const styles = OrderedSizes.map((size) => {
        const { fontSize, lineHeight } = props[size] || base;
        return createMediaString(
            className,
            EBreakpointValues[size],
            fontSize,
            lineHeight,
            applyCSSToChild,
        );
    })
        .reverse()
        .join('\n');

    return (
        <>
            <style>{styles}</style>
            <Block component={component} {...props} className={className} />
        </>
    );
};
