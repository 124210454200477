import { StatsigConfiguration, StatsigUser } from '@eventbrite/statsig';
import { ModuleInterface } from '../../components/ModuleList';

export interface AppProps {
    modules: {
        body_content: [ModuleInterface];
        body_header?: any;
    };
    env: any;
    user: any;
    request: any;
    wagtail_toolbar?: {
        edit_page_link: string;
        login_link: string;
    };
    include_footer: boolean;
    footerLinks: any;
    should_use_webpack_template: boolean;
    app_version: string;
    app_name: string;
    breadcrumbs: [
        {
            title: string;
            [key: string]: any;
        },
    ];
    should_display_breadcrumbs: boolean;
    theme: THEME_TYPES;
    featureFlags: any;
    feature_flags: any;
    url?: string;
}

interface StatsigRequiredOptions {
    user: StatsigUser;
}

export type StatsigInitializationOptions = Partial<StatsigConfiguration> &
    StatsigRequiredOptions;

export enum THEME_TYPES {
    EDGY = 'Edgy',
    MELLOW = 'Mellow',
    VIBRANT = 'Vibrant',
    SMART = 'Smart',
}

export enum VARIANTS {
    CORE_DARK = 'core_dark',
    CORE_LIGHT = 'core_light',
    CORE_GRAY = 'core_gray',
    MOOD_01 = 'mood_01',
    MOOD_02 = 'mood_02',
    MOOD_03 = 'mood_03',
}
export interface TokenOptions {
    variant?: VARIANTS;
    config?: any;
}
export type BackgroundTokens =
    | 'background'
    | 'overlay-gradient'
    | 'background-v2';
export type UITokens =
    | 'ctaLink'
    | 'card-ctaLink'
    | 'text'
    | 'card-text'
    | 'text-v2'
    | 'video-headline'
    | 'video-tagline'
    | 'video-description';
export type ButtonTokens = 'button-primary' | 'button-secondary';
export type CardTokens = 'card';
export type ExpansionPanelTokens =
    | 'expansion-panel-content'
    | 'expansion-panel-heading'
    | 'expansion-panel-trigger'
    | 'divider';
export type ThemeTokens =
    | BackgroundTokens
    | UITokens
    | ButtonTokens
    | CardTokens
    | ExpansionPanelTokens;
