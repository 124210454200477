import React from 'react';

import { CheckChunky } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import { Button as MarmaladeButton } from '@eventbrite/marmalade';
import { Divider, DividerDirection } from '../shared';
import { HELP_ARTICLES, STARTING_PRICES } from './constants/pricingPlan';
import './flexiblePricing.scss';

export interface FlexiblePricingProps {
    tld: string;
    serviceFee: string;
    processingFee?: string;
    isInTop6TLD?: boolean;
}

export const FlexiblePricing: React.FC<FlexiblePricingProps> = (props) => {
    const { tld, serviceFee, processingFee } = props;
    const startingPrice = STARTING_PRICES[tld];
    const helpLink = HELP_ARTICLES[tld];

    return (
        <section className="flexible-pricing">
            <div className="flexible-pricing__content">
                <div className="flexible-pricing__content--header">
                    <div className="flexible-pricing__content--header-text">
                        <h2>
                            {props.isInTop6TLD
                                ? gettext('Publish your events for free')
                                : gettext('Flexible pricing, clear value')}
                        </h2>
                    </div>
                    <p className="flexible-pricing__content--header-description">
                        {gettext(
                            "Reach more people on the world's largest and most trusted events marketplace. Everything you need to host standout events and earn more.",
                        )}
                    </p>
                </div>
                <div>
                    <a href="/signin/signup/?referrer=/manage/events/create/">
                        <MarmaladeButton
                            size="block"
                            variant="primary"
                            className="flexible-pricing__content--button"
                        >
                            {gettext('Get started for free')}
                        </MarmaladeButton>
                    </a>
                </div>
            </div>
            <div className="flexible-pricing__main-divider">
                <Divider />
            </div>
            <div className="flexible-pricing__info-container">
                <Divider className="flexible-pricing_horizontal-sub-divider" />
                <div className="flexible-pricing__info-container__toolkit-section">
                    <div className="flexible-pricing__info-container__toolkit-section--header">
                        <h3>
                            {gettext(
                                'The all-in-one toolkit to grow your events',
                            )}
                        </h3>
                    </div>
                    <ul className="flexible-pricing__info-container__toolkit-section--list">
                        <li>
                            <CheckChunky
                                className="flexible-pricing__info-container__toolkit-section--list-checkmark"
                                aria-hidden="true"
                            />
                            <span className="flexible-pricing__toolkit-list__text">
                                {gettext(
                                    'Flexible ticket types for in-person or online events',
                                )}
                            </span>
                        </li>
                        <li>
                            <CheckChunky
                                className="flexible-pricing__info-container__toolkit-section--list-checkmark"
                                aria-hidden="true"
                            />
                            <span className="flexible-pricing__toolkit-list__text">
                                {gettext(
                                    'Integrated email and social media marketing tools',
                                )}
                            </span>
                        </li>
                        <li>
                            <CheckChunky
                                className="flexible-pricing__info-container__toolkit-section--list-checkmark"
                                aria-hidden="true"
                            />
                            <span className="flexible-pricing__toolkit-list__text">
                                {gettext(
                                    'Secure and seamless attendee checkout',
                                )}
                            </span>
                        </li>
                        <li>
                            <CheckChunky
                                className="flexible-pricing__info-container__toolkit-section--list-checkmark"
                                aria-hidden="true"
                            />
                            <span className="flexible-pricing__toolkit-list__text">
                                {gettext('Payouts before your event')}
                            </span>
                        </li>
                        <li>
                            <CheckChunky
                                className="flexible-pricing__info-container__toolkit-section--list-checkmark"
                                aria-hidden="true"
                            />
                            <span className="flexible-pricing__toolkit-list__text">
                                {gettext('Detailed analytics and reporting')}
                            </span>
                        </li>
                    </ul>
                </div>
                <Divider
                    direction={DividerDirection.vertical}
                    className="flexible-pricing_vertical-sub-divider"
                />
                <Divider className="flexible-pricing_horizontal-sub-divider" />
                <div className="flexible-pricing__fees-section">
                    <div className="flexible-pricing__fees-section--header">
                        <h3>
                            {props.isInTop6TLD
                                ? gettext('Low fees for your attendees')
                                : gettext(
                                      'Low fees for you and your attendees',
                                  )}
                        </h3>
                    </div>
                    <div className="flexible-pricing__fees-section--content">
                        {!props.isInTop6TLD && (
                            <>
                                {gettext('You pay:')}
                                <ul className="flexible-pricing__fees-section--content-creator-list">
                                    <li>
                                        {gettext(
                                            'Publish events with up to 25 tickets for free',
                                        )}
                                    </li>
                                    <li>
                                        {gettext(
                                            'Sell more tickets with plans starting at %(startingPrice)s',
                                            {
                                                startingPrice: startingPrice,
                                            },
                                        )}
                                    </li>
                                    <li>
                                        {gettext(
                                            'Subscribe and save on frequent events',
                                        )}
                                    </li>
                                </ul>
                            </>
                        )}
                        {!props.isInTop6TLD && gettext('Attendees pay:')}
                        <ul className="flexible-pricing__fees-section--content-attendees-list">
                            <li>{gettext('No fees for free events')}</li>
                            <li>{serviceFee}</li>
                            {processingFee && <li>{processingFee}</li>}
                            <li>
                                {props.isInTop6TLD
                                    ? gettext(
                                          'Ticketing Fees are paid by ticket buyers on paid tickets unless you choose to cover them',
                                      )
                                    : gettext(
                                          'Ticketing Fees are paid by ticket buyers unless you choose to cover them',
                                      )}
                            </li>
                        </ul>
                        <a href={helpLink} target="_blank" rel="noreferrer">
                            {gettext('Find out more about how fees work')}
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};
