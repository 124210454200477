// import isInternalUrl from '@docusaurus/isInternalUrl';
import { Button, Typography } from '@eventbrite/marmalade';
import React from 'react';
import { isExternalUrl } from '../../utils/context/utils';

export interface CtaButtonInterface {
    buttonUrl: string;
    buttonText: string;
    buttonClasses?: string;
    pageReference?: string;
    size?: string;
    variant?: string;
}

class CtaButton extends React.PureComponent<CtaButtonInterface> {
    render() {
        const {
            buttonText,
            buttonUrl,
            buttonClasses,
            size,
            variant,
            pageReference,
        } = this.props;

        const isExternal = isExternalUrl(buttonUrl);

        return (
            <>
                <div className="eds-g-cell eds-g-cell-12-12 mktg-button-overlap cta-margin-top">
                    <a
                        href={
                            pageReference === '' || pageReference === undefined
                                ? buttonUrl
                                : pageReference
                        }
                        {...(isExternal && {
                            target: '_blank',
                            rel: 'nofollow noopener noreferrer',
                        })}
                    >
                        {variant === 'text' ? (
                            <>
                                <Typography
                                    className={buttonClasses}
                                    as="span"
                                    variant="body-lg"
                                >
                                    {buttonText}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Button
                                    variant="primary"
                                    className={buttonClasses}
                                    sizeVariant={
                                        size == 'medium' ? 'medium' : 'large'
                                    }
                                >
                                    {buttonText}
                                </Button>
                            </>
                        )}
                    </a>
                </div>
            </>
        );
    }
}

export default CtaButton;
