import React from 'react';
import { sanitizeHTML } from '../../components/modules/utils';

interface RawHtmlProps {
    rawHtmlBlock: string;
}

const RawHTMLModule = ({ rawHtmlBlock }: RawHtmlProps) => {
    return (
        <div
            data-testid="raw-html-module"
            className="html_content"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: sanitizeHTML(rawHtmlBlock),
                //__html: rawHtmlBlock,
            }}
        />
    );
};
export default RawHTMLModule;
