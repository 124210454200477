import { EBreakpointValues } from '@eventbrite/wagtail-components';
import { ThemeTokens } from '../types';

type StyleValue = (className: string, tokens: any, options?: any) => string;
const PixelRem = 1 / 16;
const ArrowMapping: Record<string, string> = {
    down: '"\\2193"',
    right: '"\\2192"',
};

const button = (className: string, tokens: any) => `
    .${className} {
        background-color: ${tokens.background};
        color: ${tokens.color};
        fill: ${tokens.fill};
        border: ${tokens.border};
    }

    .${className}:hover {
        background-color: ${tokens.hover.background || tokens.background};
    }

    .${className}:focus {
        background-color: ${tokens.focus.background || tokens.background};
    }

    .${className}:active {
        background-color: ${tokens.active.background || tokens.background};
    }
`;

const background = (className: string, tokens: any) => `
    .${className} {
        background-color: ${tokens.background};
    }
`;

const overlayGradient = (className: string, tokens: any, options: any) => `
    .${className} {
        background: linear-gradient(${options?.angle || 'to bottom'},
        ${
            options?.colors
                ? options.colors.join(', ')
                : `transparent 25%, ${
                      options?.useBackground ? tokens.background : tokens.color
                  } 125%`
        });
    }
`;

const card = (className: string, tokens: any, options?: any) => `
    .${className} {
        background-color: ${tokens.background};
        box-shadow: ${
            options?.noShadow
                ? 'none'
                : '0px 1px 17px rgb(40 44 53 / 10%), 0px 2px 5px rgb(40 44 53 / 10%)'
        };
    }
`;

const text = (className: string, tokens: any) => `
    .${className} {
        color: ${tokens.color};
    }

    .${className} a{
        color: ${tokens.color};
        font-weight: 600;
        text-decoration: none;
        border-bottom: 2px solid ${tokens.underline};
    }
`;

const ctaLink = (className: string, tokens: any, options?: any) => `
    .${className} {
        border-bottom: 2px solid ${tokens.border};
        color: ${tokens.color};
        position: relative;
        padding-right: 1.5rem;
        box-sizing: border-box;
        font-size: ${18 * PixelRem}rem;
        line-height: ${24 * PixelRem}rem;
        display: inline-block;
    }

    .${className}:visited, .${className}:hover, .${className}:active {
        color: ${tokens.color};
    }

    .${className}:after {
        content: ${ArrowMapping[options?.direction || 'right']};
        position: absolute;
        right: 0;
    }

    @media (max-width: ${EBreakpointValues.large}px) {
        .${className} {
            font-size: ${16 * PixelRem}rem;
            line-height: ${24 * PixelRem}rem;
        }
    }

    @media (max-width: ${EBreakpointValues.medium}px) {
        .${className} {
            font-size: ${14 * PixelRem}rem;
            line-height: ${20 * PixelRem}rem;
        }
    }
`;

const expansionPanelContent = (className: string, tokens: any) => `
.${className} ul {
    background-color: ${tokens.background};
    color:  ${tokens.color};
}
`;

const expansionPanelHeading = (className: string, tokens: any) => `
.${className} {
    color: ${tokens.color};
}
`;

const expansionPanelTrigger = (className: string, tokens: any) => `
.${className} {
    svg {
        color:  ${tokens.color} !important;
    }
}
`;

const divider = (className: string, tokens: any) => `
.${className} {
    background-color:  ${tokens.color} ;
}
`;

const backgroundV2 = (className: string, tokens: any) => `
    .${className} {
        background-color: ${tokens.background};
    }
`;

const textV2 = (className: string, tokens: any) => `
    .${className} {
        color: ${tokens.color};
    }
`;

const videoHeadline = (className: string, tokens: any) => `
    .${className} {
        color: ${tokens.color};
    }
`;

const videoTagline = (className: string, tokens: any) => `
    .${className} {
        color: ${tokens.color};
    }
`;

const videoDescription = (className: string, tokens: any) => `
    .${className} {
        color: ${tokens.color};
    }
`;

export const styles: Record<ThemeTokens, StyleValue> = {
    background,
    text,
    'overlay-gradient': overlayGradient,
    'card-text': text,
    card,
    'button-primary': button,
    'button-secondary': button,
    ctaLink,
    'card-ctaLink': ctaLink,
    'expansion-panel-content': expansionPanelContent,
    'expansion-panel-heading': expansionPanelHeading,
    'expansion-panel-trigger': expansionPanelTrigger,
    divider: divider,
    'background-v2': backgroundV2,
    'text-v2': textV2,
    'video-headline': videoHeadline,
    'video-tagline': videoTagline,
    'video-description': videoDescription,
};
